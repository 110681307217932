(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/banner_common/handlebar-helpers/assets/javascripts/actions-helper.js') >= 0) return;  svs.modules.push('/components/banner_common/handlebar-helpers/assets/javascripts/actions-helper.js');

'use strict';

const isServer = typeof exports === 'object';
function registerHelpers(hbs) {
  hbs.registerHelper('dynamicActionTemplate', (actionType, actionConfig, clickTrackingString) => {
    var _actionType;
    let template;
    let compiled;
    if (actionType === 'no-action') {
      return;
    }
    actionType = (_actionType = actionType) === null || _actionType === void 0 ? void 0 : _actionType.replace(/-/g, '_');
    if (isServer) {
      const hbs = global.internalInstances.get('hbs');
      template = actionType && hbs.cache["components-banner_actions-".concat(actionType, "-").concat(actionType)];
    } else {
      template = actionType && svs.banner_actions[actionType].templates[actionType];
    }
    if (typeof template === 'function') {
      compiled = template;
    } else if (typeof template === 'string') {
      compiled = hbs.partials[template];
    }
    if (!compiled) {
      return;
    }
    return new hbs.SafeString(compiled({
      action: actionConfig,
      clickTrackingString,
      formTrackingString: clickTrackingString
    }));
  });
}
if (typeof exports === 'object') {
  registerHelpers(require('hbs'));
} else {
  registerHelpers(Handlebars);
}

 })(window);