(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/banner_common/handlebar-helpers/assets/javascripts/banner-helpers.js') >= 0) return;  svs.modules.push('/components/banner_common/handlebar-helpers/assets/javascripts/banner-helpers.js');

'use strict';

function registerHelpers(hbs) {
  hbs.registerHelper('getRel', action => {
    if ((action === null || action === void 0 ? void 0 : action.linkType) === 'external') {
      if (!action.hasOwnProperty('linkNofollow')) {
        return 'nofollow noopener noreferrer';
      }
      return "".concat(action.linkNofollow ? 'nofollow' : '', " noopener noreferrer");
    }
  });
  hbs.registerHelper('isEven', i => {
    if (i % 2 !== 0) {
      return true;
    }
  });
  hbs.registerHelper('isOdd', i => {
    if (i % 2 === 0) {
      return true;
    }
  });

  hbs.registerHelper('setDefault', function (defaultParam) {
    let param = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : undefined;
    return param !== undefined ? param : defaultParam;
  });

  hbs.registerHelper('limitContent', (content, limit) => {
    if ((content === null || content === void 0 ? void 0 : content.length) > limit) {
      return "".concat(content.slice(0, limit), "...");
    }
    return content;
  });
  hbs.registerHelper('translateProduct', product => {
    const mapping = {
      '': '',
      default: 'Svenska Spel',
      eurojackpot: 'Eurojackpot',
      lotto: 'Lotto',
      vikinglotto: 'Vikinglotto',
      keno: 'Keno',
      kenoxpress: 'Kenoxpress',
      rubbet: 'Rubbet',
      triss: 'Triss',
      biltriss: 'BilTriss',
      dubbeltriss: 'DubbelTriss',
      minitriss: 'MiniTriss',
      supportern: 'Supportern',
      lordagsgodis: 'Lördagsgodis'
    };
    return mapping[product] || product;
  });

  hbs.registerHelper('renderCrmAttributes', crm => {
    if (!crm) {
      return;
    }
    let attributes = '';
    const crmKeys = crm && Object.keys(crm);
    if (crmKeys) {
      crmKeys.forEach(key => {
        attributes += "data-crm-".concat(key, "=\"").concat(crm[key], "\" ");
      });
    }
    return new hbs.SafeString(attributes);
  });
}
if (typeof exports === 'object') {
  registerHelpers(require('hbs'));
} else {
  registerHelpers(Handlebars);
}

 })(window);